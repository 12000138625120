body {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}

#root {
    height: 100%;
}

.mainContainer {
    height: 100%;
}

.routingContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.routingContent {
    height: 100%;
    flex: 1;
}

#login-page {
    height: 100%;
    background: url(images/login-background.jpg), url(images/login-background-lowres.jpg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#login-page .header {
    color: #344563;
    text-align: center;
    margin-top: 20px;
}

#login-page .panel {
    width: 450px;
    background-color: white;
    border-radius: 5px;
}

#login-page .panel form {
    padding: 5px 60px 25px 60px;
}

#login-page .panel h2 {
    width: 100%;
    text-align: center;
}

#login-page .panel form div div {
    width: 100%;
}

#login-page .footer {
    color: #344563;
}

#login-button {
    background: #09ACE3;
    padding: 0px 25px;
}

#login-button:hover {
    background: #09ACE3;
}

.breadCrumbs {
    padding: 5px 0 0 20px;
}

.breadCrumbs div {
    padding: 0;
}

.breadCrumbs div div div {
    padding: 0 8px;
}

.breadCrumbs a {
    color: #5E6C84;
}

.page {
    height: 100%;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
}

.pageFullScreen {
    height: 100%;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
}

.pageOverOverviewMap {
    height: 100%;
}

.pageOverviewMap {
    margin: 0;
    min-height: 300px;
    z-index: 100;
    flex: 1 1 calc(100%);
    background-color: whitesmoke;
    height: 100%;
    /* cursor: default; */
}

.imageMap {
    margin: 0;
    margin-top: 2px;
    min-height: 150px;
    z-index: 100;
    flex: 1 1 calc(100%);
    background-color: white;
    height: 100%;
    cursor: default;
}

.pageMaximized {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.mapWithStationingSlider {
    flex: 1;
    display: flex;
    flex-direction: row;
}

.mapWithStationingSlider .stationingSlider {
    width: 36px;
    height: 99%;
    display: flex;
    flex-direction: column;
}

.map {
    z-index: 299;
}

.mapWithStationingSlider .map {
    flex: 1;
    height: 99%;
}

.stationingSlider .slider {
    flex: 1;
    background: rgba(9, 30, 66, 0.04);
    margin-top: 10px;
}

.sideBar {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 200px;
}

.diagramshorizontal {
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.diagramshorizontal .diagram {
    flex: 1;
}

.diagramsvertical {
    flex: 1;
    display: flex;
    flex-direction: column;
}

/* .tableContainer {} */

.tableContainer table a {
    color: #09ACE3 !important;
    cursor: pointer;
}

.homeContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: -5px;
}

.homeContainer #previewMaps,
.homeContainer #tables {
    margin-left: 5px;
}

.homeProjectPreviewContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: -5px 10px -5px -5px;
}

.projectPreviewContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: -5px;
}

.projectPreview {
    min-width: 225px;
    max-width: 225px;
    border: 1px solid #DFE1E6;
    border-radius: 3px;
    color: #5E6C84;
    margin: 5px;
}

.quicksearch>div>div {
    border-color: #5E6C84
}

/* chrome specific */

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 30px #FAFBFC inset;
}

input:-webkit-autofill:hover {
    -webkit-box-shadow: 0 0 0px 30px #EBECF0 inset;
}

.flexRowContainer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: stretch;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
}

.flexRowContainerLine {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 1rem;
}

.flexColumnMainContainer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    align-items: center;
    justify-content: stretch;
    width: 100%;
    gap: 1rem;
}

.flexColContainer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: stretch;
    align-items: stretch;
    justify-content: stretch;
    height: 100%;
}

.flexDefaultModalContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: stretch;
    align-items: stretch;
    flex-basis: 0;
    justify-content: flex-start;
}

.flexDefaultModalOverlay {
    display: flex;
    order: 1;
    width: 100%;
    flex-direction: column;
    flex-wrap: nowrap;
    /* max-width: 600px; */
    /* min-width: 400px; */
    flex-shrink: 20;
}

.flexDefaultModalSensors {
    order: 2;
    flex-grow: 2;
    width: 100%;
}

.flexDefaultModalFrame {
    display: flex;
    order: 2;
    flex-grow: 7;
    width: 100%;
    /* min-width: 820px; */
    /* margin-top: 20px; */
    align-content: stretch;
    flex-shrink: 11;
}

.gridContainer {
    display: grid;
}

.gridContainer_2_to_5 {
    display: grid;
    grid-template-columns: 2fr 5fr;
}

.gridContainerTwoColumns {
    display: grid;
    grid-auto-rows: minmax(24px, auto);
    grid-template-columns: 2fr 5fr;
    gap: 2px 10px;
}

.flexRowContainerTest {
    align-items: center;
}

.flexRowMiddleContainer {
    display: flex;
    display: -webkit-flex;
    flex-grow: 3;
    -webkit-flex-direction: column;
    flex-direction: column;
}

.flexRowBottomContainer {
    flex-grow: 2;
    display: flex;
    flex-flow: row nowrap;
    justify-content: stretch;
}

.flexCellContainer {
    margin: 5px;
}

.flexCellContainer_column {
    display: flex;
    flex-direction: column;
}

.flexCellContainer_g1 {
    margin: 5px;
    flex-grow: 1;
}

.flexCellContainer_g1_m0 {
    flex-grow: 1;
}

.flexCellContainer_g1_30 {
    margin: 5px;
    flex-grow: 1;
    flex-basis: 33%;
}

.skeletonContainer {
    background-color: #F4F5F7;
    border-radius: 5px;
    margin: 5px;
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
}

.projectsOverviewLegend {
    padding: 3px 4px;
    font: 10px/12px Arial, Helvetica, sans-serif;
    background: white;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    line-height: 18px;
    color: #555;
}

.projectsOverviewLegend div {
    margin: 3px;
    align-items: center;
    display: flex;
}

.projectsOverviewLegend i {
    width: 18px;
    height: 18px;
    float: left;
    margin-right: 8px;
    opacity: 0.7;
}

.tooltipHeader {
    text-align: center;
    font-weight: bold;
    font-size: 14px;
}

.tooltipItemError {
    color: red;
}

.geoJsonMarker {
    background: transparent;
    border: 0px transparent;
    white-space: nowrap;
    margin-left: 0 !important;
    margin-top: 0 !important;
}

/* .geoJsonMarkerText {
    width: auto;
    height: auto;
} */

.geoJsonMarkerText>div>span {
    display: inline-block;
    transform: translateY(-50%) translateX(-50%);
}

.geoJsonMarkerText>div>img {
    position: absolute;
    display: inline-block;
    margin-top: -38px;
    margin-left: -8px;
    width: 16px;
    height: 16px;
    /* transform: translateY(-50%) translateX(-50%); */
}

/* .geoJsonMarkerText > div > img:hover {
    cursor: grab;
} */

.stacked-icon-top {
    z-index: 999999 !important;
}

.filterDropList {
    z-index: 9999;
}

.projectLogo {
    width: 230px;
    height: 50px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 5px;
}

.caption {
    font-size: 1.2em;
    will-change: transform;
    font-style: inherit;
    font-weight: 500;
    letter-spacing: -0.008em;
    line-height: 1.2;
    margin-bottom: 8px;
    text-align: left;
}

.flexContainer {
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: stretch;
}

.flexSpinnerContainer {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 100%;
}

.inlineFlexContainer {
    display: -webkit-inline-flex;
    display: flex;
    white-space: nowrap;
    align-self: center;
}

.cursorAsIconT,
.cursorAsIconT .pageOverviewMap {
    cursor: url(resources/icons/T.png), auto;
}

.cursorAsIconLink,
.cursorAsIconLink .pageOverviewMap {
    cursor: url(resources/icons/link-26.png), auto;
}

.cursorAsIconDocument,
.cursorAsIconDocument .pageOverviewMap {
    cursor: url(resources/icons/document_24px.png), auto;
}

.cursorAsCamera,
.cursorAsCamera .pageOverviewMap {
    cursor: url(resources/icons/camera.png) 12 12, auto;
}

.localMapObjectListIcon {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 24px;
    width: 24px;
}

.projectViewTreeItemIsLoading {
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    font-size: 12;
    line-height: 24px;
    padding-top: 8px;
}

.treeItemIcon {
    display: inline-block;
    width: 24px;
    justify-content: center;
    cursor: pointer;
}

.treeItem {
    display: flex;
    align-items: center;
}

.loraTreeItem {
    display: flex;
    align-items: center;
    margin: 0.5em 0;
}

.treeItemElementIcon {
    display: -webkit-inline-flex;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

code {
    padding: 2px 4px;
    font-size: 90%;
    color: #c7254e;
    background-color: #f9f2f4;
    border-radius: 4px;
}

.layersDialogIcon {
    height: 24px;
    width: 24px;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    align-items: center;
}

.processingItemStep {
    color: #001a33;
    font-size: 14px;
    margin-top: 3px;
}

.niceProjectLogoFieldLabel {
    font-size: 0.85em;
    font-style: inherit;
    line-height: 1.33;
    color: #6B778C;
    font-weight: 600;
    display: inline-block;
    margin-top: 0;
    margin-left: 10px;
}

.niceProjectLogoContainer {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
    margin: 5px;
}

.niceProjectLogoContainerLabel {
    max-width: 230px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.logoManagerProjectView {
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    margin: 5px 0;
}

.logosManagerAllView {
    height: 100%;
    border: 1px solid lightgray;
    padding: 5px;
    border-radius: 4px;
    display: flex;
    display: -webkit-flex;
    flex-flow: row wrap;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    align-content: flex-start;
}

.boldRow {
    font-weight: bold;
}

.tabWithWrap div[role=tablist] {
    flex-wrap: wrap;
}

.pageHeader {
    align-items: center;
    display: flex;
    justify-content: space-between;
    /* padding: 0.6em 0; */
}

.pageHeader:hover {
    text-decoration: none;
    cursor: auto;
}

.reportHeader {
    color: #344563;
}

.reportOverlaySettingsButtonPanel {
    display: flex;
    flex-flow: row nowrap;
}

.reportOverlayControlFrame {
    border: 0;
    margin: 0.5em;
}

.reportOverlayModalControlFrame {
    display: grid;
    border: 0;
}

.reportOverlayCell {
    width: 50%;
}

.reportOverlayBoolLabel {
    width: 70%;
}

.reportOverlayBoolBox {
    width: 30%;
}

.reportOverlayRow {
    align-items: center;
    min-height: 32px;
}

.MapItemTooltip {
    width: max-content;
}

.logbookComment {
    font-size: 12px;
    font-style: italic;
}

.alarmedMarkerCluster {
    z-index: 1000 !important;
}

.geovisLabel {
    color: #172B4D;
    font-weight: bold;
    font-size: 12px;
}

.geovisDynamicTable {
    min-width: 400px;
}

.geovisDynamicTable table tbody tr:nth-of-type(odd) {
    background-color: #f3f3f3;
}

.geovisDynamicTableSelectable {
    min-width: 200px;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.geovisDynamicTableSelectableFullWidth {
    min-width: 200px;
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.geovisDynamicTableSelectable table tbody tr:nth-of-type(odd) {
    background-color: #f3f3f3;
}

.parentCompaniesTable table tbody tr:nth-of-type(odd) {
    background-color: #f3f3f3;
}

.stickyHeadCell {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
}

.stickyHeadCell>div {
    position: absolute;
    inset: 0px;
    overflow: auto;
    margin: 0;
}

.stickyHeadCell table th {
    position: sticky;
    top: 0;
    background: white;
    z-Index: 10;
}

.stickyHeadCell table th:hover {
    background: #ebecf0
}

.reportPageContainer {
    border: 2px solid black;
}

.reportFooterContentContainer {
    height: 32px;
}

.reportFooterContentContainer>div {
    border-top: 2px solid black;
}

.reportContentContainer,
.reportContentContainerPrint {
    padding: 10px 10px 0 10px;
    margin: 0 auto;
}

@media print {
    .reportContentContainerPrint:not(:last-child) {
        page-break-after: always;
    }
}

.reportHeaderContentContainer {
    width: 100%;
    max-height: 120px;
    margin-left: auto;
    margin-right: auto;
    display: table;
}

.reportHeaderTitleCell {
    width: 100%;
    font-size: 24px;
    font-weight: bold;
    border-bottom: 2px solid black;
}

.reportFooterContentContainer {
    border-collapse: collapse;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: table;
}

.reportFooterTextCell {
    font-size: 16px;
    margin-left: 10px;
    margin-top: 3px;
    margin-bottom: 3px;
    width: 80%;
}

.reportFooterPageNumCell {
    font-size: 16px;
    margin-right: 5px;
    margin-top: 3px;
    margin-bottom: 3px;
    width: 20%;
    text-align: right;
}

.geovisChartTab {
    width: 100%;
    padding-top: 5px;
    height: 100%;
    overflow: hidden auto;
    display: flex;
    flex-direction: column;
    gap: 7px
}

.geovisLogbookTab {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 7px;
    padding-top: 5px;
}

.geovisReportTab {
    width: 100%;
    padding-top: 5px;
    height: 100%;
    overflow: hidden auto;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.geovis4TableTab {
    padding-top: 5px;
    display: flex;
    flex-direction: column;
    flex-grow: 2;
}

.geovis4TableConstructorTab {
    padding-top: 5px;
    height: 100%;
}

.dtsSectionTab {
    width: 100%;
    padding-top: 5px;
}

.geovisLogbookReportGroupHeaderLine {
    text-align: center;
    font-weight: bold;
    height: 50px;
    display: flex;
    align-items: center;
    border-bottom: solid black 2px;
    justify-content: center;
    background-color: lightgray;
}

.geovisLogbookReportGroup {
    display: flex;
    flex-direction: column;
}

.geovisLogbookReportTableHeaderLine {
    display: flex;
    height: 20px;
    background-color: lightgray;
}

.geovisLogbookReportDataLine {
    display: flex;
    min-height: 40px;
}

.geovisLogbookReportDateColumn {
    display: flex;
    justify-content: center;
    border-right: solid black 2px;
    align-items: center;
    width: 200px;
    border-bottom: 2px solid black;
    flex-shrink: 0;
}

.geovisLogbookReportMainColumn {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    border-bottom: 2px solid black;
    padding-left: 2px;
    width: 100%;
}

.geovisLogbookReportOtherColumn {
    display: flex;
    justify-content: center;
    border-left: solid black 2px;
    align-items: center;
    width: 200px;
    flex-shrink: 0;
    border-bottom: 2px solid black;
}

.geovisLogbookReportPictureColumn {
    display: flex;
    justify-content: center;
    border-left: solid black 2px;
    align-items: center;
    width: 300px;
    flex-shrink: 0;
    border-bottom: 2px solid black;
}

.geovisLogbookReportPictureDataLine {
    display: flex;
    height: 300px;
}

.projectViewNavigationRowContent {
    height: 28px;
    display: flex;
    flex-grow: 1;
    flex-wrap: nowrap;
}

.projectViewNavigationRowContentText {
    display: inline-block;
    flex-wrap: nowrap;
    flex: 1 1 auto;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.separator {
    width: 2px;
    background: #bdbdbd;
    left: 50%;
    height: 100%;
    flex-shrink: 0;
    justify-self: center;
}

.horizontalSeparator {
    height: 2px;
    background: #bdbdbd;
    width: 75%;
    flex-grow: 0;
    align-self: center;
}

.horizontalSeparatorFull {
    height: 2px;
    background: #bdbdbd;
    width: 100%;
    flex-grow: 0;
    align-self: center;
}

.geovisImageRenderViewContent {
    display: flex;
    height: 100%;
}

.mapSectionTab {
    width: 100%;
    /* padding-top: 5px; */
    height: 100%;
    overflow: hidden auto;
    display: flex;
    flex-direction: column;
    gap: 7px
}

/* @deprecated */
.mapSectionViewParentContainer {
    margin-left: auto;
    margin-right: auto;
}

.mapSectionRenderViewContent {
    display: flex;
    height: 100%;
}

.mapSectionView {
    overflow: hidden;
    justify-content: center;
    width: auto;
    height: auto;
    margin: 5px;
    /* margin-top: 5px;
    margin-bottom: 5px; */
}

.mapSectionView .leaflet-control-container .leaflet-top,
.mapSectionView .leaflet-control-container .leaflet-bottom .leaflet-right {
    display: none;
}

.mapSectionView .leaflet-tooltip-pane {
    font-size: 0.8em;
    z-index: 9999;
}

.mapSectionViewLeft0Tooltip,
.mapSectionViewLeft25Tooltip,
.mapSectionViewLeft75Tooltip,
.mapSectionViewLeft100Tooltip {
    margin-top: -6px;
}

.mapSectionViewLeft2Tooltip:before {
    left: 2%;
}

.mapSectionViewLeft25Tooltip:before {
    left: 25%;
}

.mapSectionViewLeft75Tooltip:before {
    left: 75%;
}

.mapSectionViewLeft98Tooltip:before {
    left: 98%;
}

.mapSectionViewSingle {
    margin-left: 5px;
    margin-right: 5px;
}

.mapSectionViewLeft {
    margin-left: 5px;
}

.mapSectionViewRight {
    margin-left: 5px;
    margin-right: 5px;
}

.mapSectionGroup {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
}

.mapSectionMap,
.mapSectionSelectMap {
    margin: 0;
    z-index: 100;
    flex: 1 1 calc(100%);
    background-color: white;
    cursor: default;
    height: 100%;
}

.mapSectionSelectMap {
    min-height: 300px;
    height: 100%;
}

.mapSectionSelectMap .leaflet-control-layers {
    display: none;
}

.map-section-view-legend {
    background: rgba(255, 255, 255, 0.5);
}

.project-view-movements-legend {
    background: rgba(255, 255, 255, 0.5);
    padding: 2px;
    border-radius: 5px;
}

.leaflet-left .map-section-view-legend {
    margin-left: 5px;
}

.leaflet-bottom .map-section-view-legend {
    margin-bottom: 5px;
}

.sensor-movement-value-label {
    padding-top: 15px;
    background: transparent;
    border: none;
    font-weight: bold;
    font-size: 10px;
    width: 100px !important;
}

.sensor-height-bar-value-label {
    padding-top: 15px;
    background: transparent;
    border: none;
    font-weight: bold;
    font-size: 10px;
    width: 100px !important;
}

.map-section-view-legend-item {
    display: flex;
    margin-left: 0px;
    margin-bottom: 2px;
}

.map-section-view-legend-item-label {
    justify-content: center;
    margin-left: 5px;
    align-content: center;
    display: grid;
}

.content-with-table-with-fixed-head thead {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 1000;
    /* border-bottom: 2px solid #dfe1e6; */
}

.content-with-table-with-fixed-head table {
    border-collapse: separate !important;
}

.content-with-table-with-fixed-head thead th,
.content-with-table-with-fixed-head tbody tr:last-child td {
    border-bottom: 2px solid rgb(223, 225, 230);
}

.statisticGlobalList {
    display: flex;
    flex-shrink: 4;
    width: 100%;
}

.statisticGlobalList>div {
    width: 200px;
}

.statisticGlobalList table {
    width: 180px;
}

.highlightedTrInTable tr:hover {
    background-color: #dddddd;
}

.movement-vector-line {
    z-index: 600;
}

/**
Important!
Following 2 classes below manage the rendering order of Movement vectors and Movement bars
*/
/*
UPD2: disabled for intermediate
.leaflet-geovis-movement-vectors-pane {
    z-index: 10;
}

.leaflet-geovis-movement-bars-pane {
    z-index: 5;
}
*/

.height-movement-rectangle {
    stroke-width: 1px;
    stroke-linejoin: unset;
    fill-opacity: 1;
    z-index: 100;
}

.formulaSyntaxTable td {
    vertical-align: top;
}

.table-striped>tbody>tr:nth-of-type(odd) {
    background-color: #f9f9f9;
}

.report-render-element-title {
    height: 32px;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    border-bottom: 2px solid black;
    /* border-top: 2px solid black; */
    /*border-right: 0px; */
    margin: -10px -10px 10px -10px;
}

/*.leaflet-tile-container img{
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}*/

.leaflet-tile-container img {
    will-change: transform;
    outline: 1px solid transparent;
}

.leaflet-attribution-flag {
    display: none !important;
}

.report-chart-render {
    padding: 2px
}

.report-chart-render .highcharts-container,
.report-chart-render .highcharts-container svg {
    width: 100% !important;
    /* padding: 2px */
}

.dashboardPage {
    display: flex;
    flex-direction: column;
    gap: 5px;
    height: 100%;
}

.dashboardGridPage {
    display: grid;
    gap: 2px;
    height: 100%;
    grid-template-rows: auto 5vh 5vh 15vh 25vh 35vh auto
}

.dashboardRow {
    display: flex;
    flex-flow: row nowrap;
    gap: 2px;
    width: 100%;
}

.dashboardBannerRow {
    display: flex;
    flex-flow: column nowrap;
    gap: 2px;
    flex-grow: 1;
}

.dashboardChartsRow {
    /* The maximum number of columns */
    --n: 6;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(max(200px, 100%/var(--n)), 1fr));
    gap: 1em;
    flex: 4 1 25%;
    height: 100%;
}

.dashboardLastDataRow {
    display: grid;
    grid-template-columns: 2fr 3fr 3fr 1.1fr;
    /* font-size: clamp(1rem, 0.5rem + 2.5vw, 3rem); */
    gap: 1em;
}

.dashboardErrorChartsRow {
    /* The maximum number of columns */
    --n: 4;
    display: grid;
    grid-template-columns: repeat(3, minmax(200px, 3fr));
    gap: 1em;
    flex: 4 1 25%;
    height: 100%;
}

.dashboardBigTable {
    flex: 4 1 25%;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 10px gray;
    padding: 10px;
    border-radius: 10px;
    height: 100%;
}

.dashboardHeader {
    flex-grow: 2;
    /* font-size: 1.75vw; */
    font-weight: bold;
}

.dashboardWDChart {
    flex-grow: 2;
    /* border: 2px gray solid; */
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 10px gray;
    border-radius: 10px;
}

.dashboardTime {
    display: flex;
    gap: 5px;
    align-items: center;
    box-shadow: 0 0 10px gray;
    border-radius: 10px;
}

.dashboardErrorChart {
    /* flex-grow: 4; */
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 10px gray;
    border-radius: 10px;
    width: 100%;
}

.dashboardLastData {
    flex-grow: 1;
    box-shadow: 0 0 10px gray;
    border-radius: 10px;
    padding: 10px;
}

.dashboardProjectsWithoutVAWarning {
    display: grid;
    grid-template-columns: 1fr 5fr auto;
    padding: 5px;
    box-shadow: 0 0 10px gray;
    border-radius: 10px;
}

.dashboardSwitchTableButton {
    font-size: 0.8vw;
    margin-bottom: 5px;
    cursor: pointer;
    background-color: #ebecf0;
    display: flex;
    align-items: center;
    border-radius: 5px 5px 0px 0px;
    padding-left: 3px;
    padding-right: 3px;
    min-height: 32px;
}

.dashboardLogo {
    width: clamp(5rem, 5rem + 2.5vw, 10rem);
    height: clamp(1rem, 1rem + 2vh, 4rem);
    padding: 2px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: top;
}

.projectHeaderLogo {
    width: clamp(5rem, 6rem + 5vw, 13rem);
    height: clamp(1rem, 1rem + 2vh, 6rem);
    padding: 2px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: top;
}

.dashboardFontSmaller {
    font-size: clamp(0.35rem, 0.5rem + 0.5vw, 2rem);
}

.dashboardFont {
    font-size: clamp(0.5rem, 0.5rem + 0.7vw, 3rem);
}

.dashboardFontBigger {
    font-size: clamp(0.5rem, 0.5rem + 1vw, 4rem);
}

.dashboardFontBig {
    font-size: clamp(0.5rem, 0.5rem + 2vw, 6rem);
}

.dashboardFontExtraBig {
    font-size: clamp(2rem, 2rem + 2vw, 8rem);
}

.projectViewTab {
    width: 100%;
    padding-top: 5px;
}

.common-local-map-object,
.map-section-local-object {
    outline: none;
}

.project-edit-navigation-icon {
    /* stroke: #42526E; */
    padding-left: 2px;
    width: 21px;
    height: 21px;
}

.project-edit-navigation-icon.selected {
    fill: #0052CC;
    padding-left: 2px;
    width: 21px;
    height: 21px;
}

.project-edit-navigation-icon.selected-stroke {
    stroke: #0052CC;
    padding-left: 2px;
    width: 21px;
    height: 21px;
}

.projectHeaderFont {
    font-size: clamp(1rem, 0.5rem + 1vw, 4rem);
    font-weight: bold;
    flex: 1;
}

.projectSignFont {
    font-size: clamp(1rem, 0.5rem + 1vw, 4rem);
    font-weight: bold;
}

.flexColumnContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 3;
    gap: 10px;
}

.flexLineContainer {
    display: flex;
    flex-direction: row;
    gap: 5px;
    -webkit-align-items: center;
    align-items: center;
    justify-content: stretch;
    width: 100%;
}

.computation-task-progress-label-column {
    width: '75px';
}

.computation-task-progress-name-column {
    width: '100pxx';
    flex-grow: 1;
}

.computation-task-progress-bar-column {
    min-width: '75px';
    margin-left: '2px';
}

.leaflet-draw-control-disabled {
    filter: contrast(22%) brightness(157%);
    pointer-events: none;
}

.leaflet-draw-snap-point-highlighted {
    background-color: blue;
}

.popup-overlay {
    position: fixed;
    top: -250px;
    left: -30px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-content {
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    max-width: 600px;
    text-align: center;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

.popup-message {
    font-size: 0.9em;
}

.popup-close-button {
    margin-top: 10px;
    background-color: #0052CC;
    color: white;
    border: none;
    padding: 5px 12px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
}

.popup-close-button:hover {
    background-color: #0052CC;
}