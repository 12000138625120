.react-datepicker__month-dropdown-container{
    margin-right: 20px;
}

.react-datepicker__year-dropdown-container{
    margin-left: 20px;
}

.react-datepicker__triangle{
    display: none;
}

.geovis-react-datepicker-wrapper{
    display: flex;
    min-height: 32px;
}

.react-datepicker__day--outside-month {
    color: #999;
}

.geovis-react-datepicker-input{
    text-align: center;
    cursor: pointer;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    padding-left: 6px;
    padding-right: 6px;
    padding-bottom: 0;
    padding-top: 0;
    border-color: #DFE1E6;
    border-radius: 0;
    border-style: solid;
    border-width: 2px;
    background-color: #F4F5F7;
    outline: 0px;
}

.geovis-react-datepicker-input-focused{
    border-color: #4C9AFF;
    background-color: #FFFFFF;
}

.geovis-react-datepicker-close-icon::after{
    background-color: gray;
    padding: 0;
    height: 12px;
    width: 12px;
}

.react-datepicker__time-container--with-today-button{
    top: -1px;
    right: -87px;
}

.react-datepicker-time__input-container{
        padding-left: 25px;
}

.geovis-react-datepicker-popper{
    z-index: 9999;
}

.geovis-custom-time-input-container{
    margin: 5px;
    display: flex;
    align-items: center;
    position: relative;
}

.geovis-custom-time-input{
    width: 100%;
    padding-right: 40px;
}

.geovis-custom-time-input-icon{
    position: absolute;
    right: 10px;
}

.geovis-custom-time-input-container input[type="time"]::-webkit-calendar-picker-indicator {
     display: none;
}

.geovis-custom-time-input-dropdown{
    width: 50px;
    border: none;
    text-align: center;
    font-family: sans-serif;
    font-weight: 400;
}

.geovis-custom-time-input-dropdown option{
    font-size: 16px;
    padding: 5px;
}

.geovis-custom-time-input-dropdown option:checked{
    background-color: #15a5ed;
}

.geovis-custom-time-input-dropdown option:focus,
.geovis-custom-time-input-dropdown option:hover{
    background-color: #15a5ed;
}

.geovis-custom-time-input-dropdown::-webkit-scrollbar{display:none;}
.geovis-custom-time-input-dropdown::-moz-scrollbar{display:none;}
.geovis-custom-time-input-dropdown::-o-scrollbar{display:none;}
.geovis-custom-time-input-dropdown::-google-ms-scrollbar{display:none;}
.geovis-custom-time-input-dropdown::-khtml-scrollbar {display:none;}

.geovis-custom-time-input-dropdown-container{
    position: absolute;
    left: 60px;
}

.geovis-custom-time-input-dropdown-on-top-container{
    position: absolute;
    left: 60px;
    top: 85px;
}